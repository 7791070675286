import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const HomePage = React.lazy(() => import('./components/homepage'));
const SkillsPage = React.lazy(() => import('./components/skillspage'));

export const placeholder =
  "https://assets.resume.benjaminmatthews.dev/placeholder/placeholder.svg";
export const handleImageError = (e: any) => {
  e.target.src = placeholder;
};

export default class App extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <React.Suspense>
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/skills" element={<SkillsPage />} />
        </Routes>
      </BrowserRouter>
      </React.Suspense>
    );
  }
}
